import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  RadioGroup,
} from 'me-component-library';
import {
  useGetProjectDetail,
  useGetWhiteLabelThemes,
  useTranslation,
  useUpdateProjectDetails,
} from '../../hooks';
import { useParams } from 'react-router-dom';
import { Theme } from 'design-tokens';
import { ImageDropZone } from '../ImageDropZone';
import { ImageTypes } from '../../types';

export function AppearanceForm() {
  const t = useTranslation();
  const params = useParams();
  const { data } = useGetProjectDetail(params.id as string);
  const { mutate } = useUpdateProjectDetails(params.id as string);
  const { availableThemes: themes, getSelectedThemeStyle } =
    useGetWhiteLabelThemes();

  const formSchema = z.object({
    theme: z.enum(themes as [Theme, ...Theme[]], {
      required_error: t(
        'projectDetail.appearanceForm.fields.theme.errors.required',
      ),
    }),
    name: z.string().min(2, {
      message: t('projectDetail.appearanceForm.fields.name.errors.minLength'),
    }),
    language: z.string().min(2, {
      message: t(
        'projectDetail.appearanceForm.fields.language.errors.required',
      ),
    }),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      theme: data?.theme,
      name: data?.name,
      language: data?.language ?? 'en',
    },
  });

  function onSubmit(data: z.infer<typeof formSchema>) {
    mutate(
      { projectId: params.id as string, ...data },
      {
        onSuccess: () => {
          form.reset({}, { keepValues: true });
        },
      },
    );
  }

  function reset() {
    form.reset();
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="w-full">
        <div className="flex w-auto items-end justify-end gap-4 ml-auto">
          <Button type="submit" disabled={!form.formState.isDirty}>
            {t('projectDetail.appearanceForm.buttonLabels.update')}
          </Button>
          <Button
            onClick={reset}
            variant="outline"
            disabled={!form.formState.isDirty}
          >
            {t('projectDetail.appearanceForm.buttonLabels.reset')}
          </Button>
        </div>
        <div className="w-full flex flex-col gap-12 border border-slate-200 rounded-lg mt-6 py-8 px-12">
          <div className="flex w-full gap-16 justify-between flex-wrap">
            <div className="flex flex-col gap-4">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-xl text-slate-700">
                      {t('projectDetail.appearanceForm.fields.name.label')}
                    </FormLabel>
                    <p className="text-sm text-slate-500 mt-0.5">
                      {t('projectDetail.appearanceForm.fields.name.help')}
                    </p>
                    <FormControl>
                      <Input
                        placeholder={t(
                          'projectDetail.appearanceForm.fields.name.placeholder',
                        )}
                        type="text"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="flex flex-col gap-4">
              <FormField
                control={form.control}
                name="language"
                test-id="defaultLanguage"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-xl text-slate-700">
                      {t('projectDetail.appearanceForm.fields.language.label')}
                    </FormLabel>
                    <p className="text-sm text-slate-500 mt-0.5">
                      {t('projectDetail.appearanceForm.fields.language.help')}
                    </p>
                    <FormControl>
                      <select
                        {...field}
                        className="border border-gray-300 rounded-md p-2"
                      >
                        <option value="en">
                          {t(
                            'projectDetail.appearanceForm.fields.language.options.en',
                          )}
                        </option>
                        <option value="de">
                          {t(
                            'projectDetail.appearanceForm.fields.language.options.de',
                          )}
                        </option>
                      </select>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>
          <div className="flex flex-wrap justify-between gap-y-4">
            <div className="flex-shrink-0 w-1/2">
              <div>
                <FormLabel className="text-xl text-slate-700">
                  {t('projectDetail.appearanceForm.fields.logo.label')}
                </FormLabel>
                <p className="text-sm text-slate-500 mt-0.5">
                  {t('projectDetail.appearanceForm.fields.logo.help')}
                </p>
              </div>
              <ImageDropZone
                acceptedFiles={['.jpeg', '.png', '.jpg', '.svg']}
                imageType={ImageTypes.LOGO}
              />
            </div>
            <div className="flex-shrink-0 w-1/2">
              <div>
                <FormLabel className="text-xl text-slate-700">
                  {t('projectDetail.appearanceForm.fields.favicon.label')}
                </FormLabel>
                <p className="text-sm text-slate-500 mt-0.5">
                  {t('projectDetail.appearanceForm.fields.favicon.help')}
                </p>
              </div>
              <ImageDropZone
                acceptedFiles={['.jpeg', '.png', '.jpg', '.ico', '.svg']}
                imageType={ImageTypes.FAVICON}
              />
            </div>
            <div className="flex-shrink-0 w-1/2">
              <div>
                <FormLabel className="text-xl text-slate-700">
                  {t('projectDetail.appearanceForm.fields.imglogin.label')}
                </FormLabel>
                <p className="text-sm text-slate-500 mt-0.5">
                  {t('projectDetail.appearanceForm.fields.imglogin.help')}
                </p>
              </div>
              <ImageDropZone
                acceptedFiles={['.jpeg', '.png', '.jpg', '.svg']}
                imageType={ImageTypes.IMGLOGIN}
              />
            </div>
            <div className="flex-shrink-0 w-1/2">
              <div>
                <FormLabel className="text-xl text-slate-700">
                  {t('projectDetail.appearanceForm.fields.topBanner.label')}
                </FormLabel>
                <p className="text-sm text-slate-500 mt-0.5">
                  {t('projectDetail.appearanceForm.fields.topBanner.help')}
                </p>
              </div>
              <ImageDropZone
                acceptedFiles={['.jpeg', '.png', '.jpg', '.svg']}
                imageType={ImageTypes.TOPBANNER}
              />
            </div>
          </div>
          <FormField
            control={form.control}
            name="theme"
            render={({ field }) => (
              <FormItem className="space-y-6">
                <div>
                  <FormLabel className="text-xl text-slate-700">
                    {t('projectDetail.appearanceForm.fields.theme.label')}
                  </FormLabel>
                  <p className="text-sm text-slate-500 mt-2">
                    {t('projectDetail.appearanceForm.fields.theme.help')}
                  </p>
                </div>
                <FormControl className="mt-8">
                  <RadioGroup
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                    className="grid grid-cols-5 mt-9 gap-8"
                    style={{
                      gridTemplateColumns:
                        'repeat(auto-fill, minmax(200px, 1fr))',
                    }}
                  >
                    {themes.map(theme => {
                      const { background } = getSelectedThemeStyle(theme);
                      return (
                        <FormItem
                          className="flex w-full border flex-col items-center rounded-md overflow-hidden cursor-pointer"
                          key={theme}
                          onClick={() => {
                            field.onChange(theme);
                          }}
                        >
                          <div className={`w-full h-40 ${background}`} />
                          <div className="flex w-full justify-between p-3">
                            <FormLabel>
                              {t(
                                `projectDetail.appearanceForm.fields.theme.options.${
                                  theme as Theme
                                }`,
                              )}
                            </FormLabel>
                            <FormControl>
                              <input
                                type="radio"
                                value={theme}
                                checked={field.value === theme}
                                onChange={field.onChange}
                                className="h-4 w-4"
                              />
                            </FormControl>
                          </div>
                        </FormItem>
                      );
                    })}
                  </RadioGroup>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </form>
    </Form>
  );
}
