import { Alert, Label, LoadingSpinner } from 'me-component-library';
import { useGetDomainVerification, useTranslation } from '../../hooks';
import { ClickToCopyToClipboardButton } from '../ClickToCopyToClipboardButton/ClickToCopyToClipboardButton';

type Props = {
  domain: string;
  projectId: string;
};

export const DomainSettingsSetupInstructions: React.FC<Props> = ({
  domain,
  projectId,
}) => {
  const t = useTranslation();
  const parts = domain.split('.');
  const displayDomain = parts.length > 2 ? parts[0] : '@';
  const { data, isLoading } = useGetDomainVerification(projectId);

  const renderTableBody = () => {
    if (!data) return;
    if (isLoading) return <LoadingSpinner />;
    if (data.verification.length > 0) {
      return data.verification.map((entry, index) => {
        return (
          <tr key={index} className="border-t border-gray-200">
            <td className="px-6 py-4 text-center">{entry.type}</td>
            <td className="px-6 py-4 text-center">{entry.domain}</td>
            <td className="px-6 py-4 text-center">
              <ClickToCopyToClipboardButton
                className="justify-center"
                data={entry.value}
              />
            </td>
          </tr>
        );
      });
    } else if (displayDomain !== '@') {
      return (
        <>
          <tr className="border-t border-gray-200">
            <td className="px-6 py-4 text-center">CNAME</td>
            <td className="px-6 py-4 text-center">{displayDomain}</td>
            <td className="px-6 py-4 text-center">
              <ClickToCopyToClipboardButton
                className="justify-center"
                data="dns.postmaster.digital"
              />
            </td>
          </tr>
        </>
      );
    }
    return (
      <>
        <tr className="border-t border-gray-200">
          <td className="px-6 py-4 text-center">A</td>
          <td className="px-6 py-4 text-center">{displayDomain}</td>
          <td className="px-6 py-4 text-center">
            <ClickToCopyToClipboardButton
              className="justify-center"
              data="76.76.21.21"
            />
          </td>
        </tr>
      </>
    );
  };

  return (
    <Alert>
      <div className="my-5">
        <Label className="text-xl text-slate-700">
          {t('projectDetail.domain.fields.addDomain.setup.label')}
        </Label>
      </div>
      {isLoading ? (
        <div className="flex justify-center w-full min-h-28">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <div className="w-full overflow-x-auto my-5">
            <table className="min-w-full">
              <thead>
                <tr>
                  <th className="px-6 py-3 bg-gray-100 text-center">
                    {t('projectDetail.domain.fields.addDomain.setup.type')}
                  </th>
                  <th className="px-6 py-3 bg-gray-100 text-center">
                    {t('projectDetail.domain.fields.addDomain.setup.name')}
                  </th>
                  <th className="px-6 py-3 bg-gray-100 text-center">
                    {t('projectDetail.domain.fields.addDomain.setup.value')}
                  </th>
                </tr>
              </thead>
              <tbody>{renderTableBody()}</tbody>
            </table>
          </div>
          <p className="text-sm text-slate-500 mt-0.5">
            {t('projectDetail.domain.fields.addDomain.setup.helperText')}
          </p>
        </>
      )}
    </Alert>
  );
};
