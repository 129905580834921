import { useMutation, useQueryClient } from 'react-query';
import { deleteUser, DeleteUserParams } from '../../api';

export function useDeleteUser(projectId: string) {
  const queryClient = useQueryClient();

  return useMutation((variables: DeleteUserParams) => deleteUser(variables), {
    onSuccess: () => {
      queryClient.invalidateQueries([`users`, projectId]);
    },
  });
}
