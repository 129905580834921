import { Button, Icon, cn } from 'me-component-library';
import { useState } from 'react';
import { copyToClipboard } from '../../utils';

type Props = {
  className?: string;
  data: string;
};
export const ClickToCopyToClipboardButton: React.FC<Props> = ({
  className,
  data,
}) => {
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const selectedColor = isCopied ? 'bg-green-100' : 'bg-slate-100';

  function onClick() {
    copyToClipboard(data, {
      onSuccess: () => {
        setIsCopied(true);

        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      },
    });
  }
  return (
    <div
      className={cn(
        'flex w-full px-2 justify-start items-center gap-1 rounded max-w-xs min-w-full',
        selectedColor,
        className,
      )}
    >
      <Button
        variant="ghost"
        className={` hover:${selectedColor} duration-0`}
        onClick={onClick}
        size="icon"
        data-testid="copy-button"
      >
        <Icon
          name={isCopied ? 'check' : 'copy'}
          size={16}
          className="text-slate-600"
        />
      </Button>
      <p className=" text-xs text-slate-600 truncate">{data}</p>
    </div>
  );
};
