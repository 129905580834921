import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Icon,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  useToast,
} from 'me-component-library';
import { useTranslation } from '../../hooks';
import { useDeleteUser } from '../../hooks/useDeleteUser';
import { useState } from 'react';

export interface DeleteUserModalProps {
  userId: string;
  email: string;
  projectId: string;
}

export const DeleteUserModal = ({
  email,
  userId,
  projectId,
}: DeleteUserModalProps) => {
  const t = useTranslation();
  const { mutate, isLoading, isError } = useDeleteUser(projectId);
  const { toast } = useToast();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const openDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleUserDelete = () => {
    mutate(
      {
        projectId,
        id: userId,
      },
      {
        onSuccess: () => {
          closeDeleteModal();
          toast({
            title: t('projectDetail.userList.deleteUser.toast.success.title'),
            description: t(
              'projectDetail.userList.deleteUser.toast.success.description',
              {
                email,
              },
            ),
          });
        },
      },
    );
  };

  return (
    <Dialog open={isDeleteModalOpen}>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger>
            <DialogTrigger onClick={openDeleteModal} asChild>
              <div>
                <Icon
                  name="trash"
                  className="select-none cursor-pointer text-red-500"
                />
              </div>
            </DialogTrigger>
          </TooltipTrigger>
          <TooltipContent>
            {t('projectDetail.userList.actions.delete')}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <DialogContent onCloseClick={closeDeleteModal}>
        <DialogHeader>
          <DialogTitle>
            {t('projectDetail.userList.deleteUser.title')}
          </DialogTitle>
          <DialogDescription>
            <span>
              {t('projectDetail.userList.deleteUser.subtitle.part1')}
              <span className="font-bold">
                {t('projectDetail.userList.deleteUser.subtitle.part2', {
                  email: email,
                })}
              </span>
              {t('projectDetail.userList.deleteUser.subtitle.part3')}
            </span>
          </DialogDescription>
        </DialogHeader>
        <div className="flex justify-between">
          <Button
            variant="destructive"
            data-testid="confirm-delete-user-button"
            isLoading={isLoading}
            onClick={() => handleUserDelete()}
          >
            {t('projectDetail.userList.deleteUser.button')}
          </Button>
          <Button onClick={closeDeleteModal}>
            {t('projectDetail.userList.deleteUser.cancel')}
          </Button>
        </div>
        {isError && (
          <span className="text-red-500">
            {t('projectDetail.userList.deleteUser.error')}
          </span>
        )}
      </DialogContent>
    </Dialog>
  );
};
