import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import * as z from 'zod';

import { useTranslation } from '../../hooks';
import { useParams } from 'react-router-dom';

import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
} from 'me-component-library';
import { useState } from 'react';
import { useUpdateDomain } from '../../hooks/useUpdateDomain';

export const DomainSettingsUnset: React.FC = () => {
  const t = useTranslation();
  const params = useParams();
  const { mutate, isLoading } = useUpdateDomain(params.id as string);
  const [showAlertOnSuccess, setAlertOnSuccess] = useState(false);

  const isValidDomain = (value: string) => {
    return /\./.test(value);
  };

  const formSchema = z.object({
    domain: z
      .string()
      .refine(value => isValidDomain(value), {
        message: t('projectDetail.domain.fields.errors.invalid'),
      })
      .refine(
        value => {
          if (
            value.includes('postmaster.digital') ||
            value.includes('postmaster.software')
          ) {
            return false;
          }
          return true;
        },
        {
          message: t('projectDetail.domain.fields.errors.notAvailable'),
        },
      ),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      domain: '',
    },
  });

  function onSubmitDone(data: z.infer<typeof formSchema>) {
    mutate(
      { projectId: params.id as string, ...data },
      {
        onSuccess: () => {
          setAlertOnSuccess(true);
        },
      },
    );
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmitDone)} className="w-full">
        <div className="flex flex-col gap-12 border border-slate-200 rounded-lg mt-6 py-8 px-12">
          <div className="flex gap-16">
            <div className="flex flex-col w-full gap-4">
              <FormField
                control={form.control}
                name="domain"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-xl text-slate-700">
                      {t('projectDetail.domain.fields.addDomain.label')}
                    </FormLabel>
                    <p className="text-sm text-slate-500 mt-0.5">
                      {t('projectDetail.domain.fields.addDomain.help')}
                    </p>
                    <FormControl>
                      <div className="w-96">
                        <Input
                          placeholder={t(
                            'projectDetail.domain.fields.addDomain.placeholder',
                          )}
                          type="text"
                          disabled={showAlertOnSuccess === true}
                          {...field}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="flex w-auto gap-4 mt-6">
                <Button
                  type="submit"
                  disabled={
                    !form.formState.isDirty || isLoading || showAlertOnSuccess
                  }
                  isLoading={isLoading}
                >
                  {t('projectDetail.domain.fields.addDomain.buttons.add')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Form>
  );
};
