import { z } from 'zod';
import { useParams } from 'react-router-dom';
import {
  useGetEmailTemplateDefaults,
  useGetProjectDetail,
  useTranslation,
  useUpdateProjectDetails,
} from '../../hooks';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Alert,
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Input,
  FormLabel,
  TextArea,
} from 'me-component-library';
import { useEffect, useState } from 'react';
import { Defaults } from '../../types/defaults';
import { Project } from '../../types';
import { SkeletonList } from '../SkeletonList';
import { EmailTemplatePreviewModal } from './EmailTemplatePreviewModal';

export type EmailTemplateFormProps = {
  formType: 'newUserInvite' | 'resetPassword';
};

const EmailFormTableRow = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => {
  return (
    <tr className="border-t border-gray-200">
      <td className="px-6 py-4 text-center">{title}</td>
      <td className="px-6 py-4 text-center">{description}</td>
    </tr>
  );
};

export const EmailTemplateForm = ({ formType }: EmailTemplateFormProps) => {
  const t = useTranslation();
  const params = useParams();
  const { data, isLoading } = useGetProjectDetail(params.id as string);
  const {
    data: emailTemplateDefaults,
    isLoading: emailTemplateDefaultsLoading,
  } = useGetEmailTemplateDefaults();
  const { mutate } = useUpdateProjectDetails(params.id as string);
  const [selectedLanguage, setSelectedLanguage] = useState('en');

  const formSchema = z.object({
    subject: z.string().min(2, {
      message: t(
        'projectDetail.emailTemplateForm.fields.subject.errors.minLength',
      ),
    }),
    html: z.string().min(2, {
      message: t(
        'projectDetail.emailTemplateForm.fields.subject.errors.minLength',
      ),
    }),
    language: z.string().min(2, {
      message: t(
        'projectDetail.appearanceForm.fields.language.errors.required',
      ),
    }),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      subject: '',
      html: '',
      language: 'en',
    },
  });

  const renderTemplateContent = (
    templateType: 'subject' | 'html',
    language: string,
    forceDefault: boolean = false,
  ) => {
    if (!data || !emailTemplateDefaults) {
      return '';
    }

    const prefix = language === 'en' ? 'en' : 'de';
    const suffix = formType === 'newUserInvite' ? 'NewUser' : 'ResetPwd';

    const templateData =
      templateType === 'subject' ? `${suffix}Subject` : `${suffix}Html`;

    const defaultTemplateData =
      emailTemplateDefaults[`${prefix}${templateData}` as keyof Defaults];
    const projectTemplateData =
      data[`${prefix}${templateData}` as keyof Project];
    if (projectTemplateData && forceDefault === false) {
      return data[`${prefix}${templateData}` as keyof Project] as string;
    }
    return defaultTemplateData;
  };

  const onSubmit = (data: z.infer<typeof formSchema>) => {
    const prefix = selectedLanguage === 'en' ? 'en' : 'de';
    const suffix = formType === 'newUserInvite' ? 'NewUser' : 'ResetPwd';
    const mutateData = {
      [`${prefix}${suffix}Subject`]: data.subject,
      [`${prefix}${suffix}Html`]: data.html,
    };

    mutate(
      { projectId: params.id as string, ...mutateData },
      {
        onSuccess: () => {
          form.reset({}, { keepValues: true });
        },
      },
    );
  };

  function reset() {
    form.reset();
  }

  const resetToDefault = () => {
    if (emailTemplateDefaults !== undefined) {
      form.setValue(
        'subject',
        renderTemplateContent('subject', selectedLanguage, true),
        { shouldDirty: true },
      );
      form.setValue(
        'html',
        renderTemplateContent('html', selectedLanguage, true),
        { shouldDirty: true },
      );
    }
  };

  const emailSender =
    data?.customSenderEmail && data.enableCustomSenderEmail
      ? data?.customSenderEmail
      : 'noreply@postmaster.digital';

  useEffect(() => {
    if (data !== undefined && emailTemplateDefaults !== undefined) {
      form.reset({
        subject: renderTemplateContent('subject', selectedLanguage),
        html: renderTemplateContent('html', selectedLanguage),
        language: 'en',
      });
    }
    // renderTemplateContent is a dependency, yes, but react-compiler takes care of it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, emailTemplateDefaults, selectedLanguage, form]);

  if (isLoading === true || emailTemplateDefaultsLoading === true)
    return <SkeletonList count={5} itemClassName="w-full h-52 rounded-md" />;

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="w-full">
        <div className="flex w-auto items-end justify-end gap-4 ml-auto">
          <Button type="submit" disabled={!form.formState.isDirty}>
            {t('projectDetail.appearanceForm.buttonLabels.update')}
          </Button>
          <Button
            onClick={reset}
            variant="outline"
            disabled={!form.formState.isDirty}
          >
            {t('projectDetail.appearanceForm.buttonLabels.reset')}
          </Button>
          <EmailTemplatePreviewModal
            subject={form.getValues('subject')}
            html={form.getValues('html')}
            sender={emailSender}
          />
          <Button onClick={resetToDefault} variant="outline" type="button">
            {t('projectDetail.emailTemplateForm.button.resetToDefault')}
          </Button>
        </div>
        <div className="w-full flex flex-col gap-12 border border-slate-200 rounded-lg mt-6 py-8 px-12">
          <div className="flex w-full gap-16 justify-between flex-wrap">
            <div className="flex flex-col gap-4">
              <FormItem>
                <FormLabel className="text-xl text-slate-700">
                  {t('projectDetail.appearanceForm.fields.language.label')}
                </FormLabel>
                <p className="text-sm text-slate-500 mt-0.5">
                  {t('projectDetail.emailTemplateForm.fields.language.help')}
                </p>
                <FormControl>
                  <select
                    className="border border-gray-300 rounded-md p-2"
                    value={selectedLanguage}
                    onChange={e => setSelectedLanguage(e.target.value)}
                  >
                    <option value="en">
                      {t(
                        'projectDetail.appearanceForm.fields.language.options.en',
                      )}
                    </option>
                    <option value="de">
                      {t(
                        'projectDetail.appearanceForm.fields.language.options.de',
                      )}
                    </option>
                  </select>
                </FormControl>
                <FormMessage />
              </FormItem>
            </div>
          </div>
          <FormField
            control={form.control}
            name="subject"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-xl text-slate-700">
                  {t('projectDetail.emailTemplateForm.fields.subject.label')}
                </FormLabel>
                <p className="text-sm text-slate-500 mt-0.5">
                  {t('projectDetail.emailTemplateForm.fields.subject.help')}
                </p>
                <FormControl>
                  <Input
                    placeholder={t(
                      'projectDetail.emailTemplateForm.fields.subject.label',
                    )}
                    type="text"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="html"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-xl text-slate-700">
                  {t('projectDetail.emailTemplateForm.fields.html.label')}
                </FormLabel>
                <p className="text-sm text-slate-500 mt-0.5">
                  {t('projectDetail.emailTemplateForm.fields.html.help')}
                </p>
                <FormControl>
                  <TextArea
                    placeholder={t(
                      'projectDetail.emailTemplateForm.fields.html.label',
                    )}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Alert>
            <div className="my-5">
              <FormLabel className="text-xl text-slate-700">
                {t('projectDetail.emailTemplateForm.fields.placeholders.title')}
              </FormLabel>
            </div>
            <p className="text-sm text-slate-500 mt-0.5">
              {t('projectDetail.emailTemplateForm.fields.placeholders.help')}
            </p>
            <div className="w-full overflow-x-auto my-5">
              <table className="min-w-full">
                <thead>
                  <tr>
                    <th className="px-6 py-3 bg-gray-100 text-center">
                      {t('projectDetail.domain.fields.addDomain.setup.name')}
                    </th>
                    <th className="px-6 py-3 bg-gray-100 text-center">
                      {t('projectDetail.domain.fields.addDomain.setup.value')}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <EmailFormTableRow
                    title={t(
                      'projectDetail.emailTemplateForm.fields.placeholders.email',
                    )}
                    description={t(
                      'projectDetail.emailTemplateForm.fields.placeholders.emailDescription',
                    )}
                  />
                  <EmailFormTableRow
                    title={t(
                      'projectDetail.emailTemplateForm.fields.placeholders.login',
                    )}
                    description={t(
                      'projectDetail.emailTemplateForm.fields.placeholders.loginDescription',
                    )}
                  />
                </tbody>
              </table>
            </div>
          </Alert>
        </div>
      </form>
    </Form>
  );
};
