import { UserData } from '../types';
import { api } from './api';

export type DeleteUserParams = { projectId: string } & Pick<UserData, 'id'>;

export async function deleteUser({
  projectId,
  ...user
}: DeleteUserParams): Promise<{ id: string }> {
  const response = await api.delete(
    `admin/projects/${projectId}/users/${user.id}`,
  );
  return await response.json();
}
