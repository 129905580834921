import { Tabs, TabsList, TabsTrigger, TabsContent } from 'me-component-library';
import {
  ProjectBreadcrumb,
  ProjectInfo,
  UserDataTable,
  DomainSettingsForm,
  AppearanceForm,
  BulkUserCreateModal,
  EmailTemplates,
  EmailSenderForm,
} from '../../components';
import { useTranslation } from '../../hooks';
import { CreateUserModal } from '../../components/CreateUserModal';
import { EmailQuotaForm } from '../../components/EmailQuotaForm';

export const ProjectDetail: React.FC = () => {
  const t = useTranslation();

  return (
    <>
      <ProjectBreadcrumb />
      <ProjectInfo />
      <Tabs defaultValue="users" className="w-full mt-12">
        <TabsList>
          <TabsTrigger value="users">
            {t('projectDetail.tabs.users')}
          </TabsTrigger>
          <TabsTrigger value="appearance">
            {t('projectDetail.tabs.appearance')}
          </TabsTrigger>
          <TabsTrigger value="domain">
            {t('projectDetail.tabs.domain')}
          </TabsTrigger>
          <TabsTrigger value="emailTemplates">
            {t('projectDetail.tabs.emailTemplates')}
          </TabsTrigger>
          <TabsTrigger value="emailSender">
            {t('projectDetail.tabs.emailSender')}
          </TabsTrigger>
          <TabsTrigger value="emailQuota">
            {t('projectDetail.tabs.emailQuota')}
          </TabsTrigger>
        </TabsList>
        <TabsContent value="users" className="flex flex-col justify-end">
          <div className="flex items-center justify-end gap-4">
            <CreateUserModal />
            <BulkUserCreateModal />
          </div>
          <UserDataTable />
        </TabsContent>
        <TabsContent value="appearance">
          <AppearanceForm />
        </TabsContent>
        <TabsContent value="domain">
          <DomainSettingsForm />
        </TabsContent>
        <TabsContent value="emailTemplates">
          <EmailTemplates />
        </TabsContent>
        <TabsContent value="emailSender">
          <EmailSenderForm />
        </TabsContent>
        <TabsContent value="emailQuota">
          <EmailQuotaForm />
        </TabsContent>
      </Tabs>
    </>
  );
};
