import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'me-component-library';
import { CreateUserForm } from '../CreateUserForm';
import { useTranslation } from '../../hooks';
import { useState } from 'react';

export const CreateUserModal: React.FC = () => {
  const t = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  function openModal() {
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false);
  }

  return (
    // TODO https://github.com/shadcn-ui/ui/issues/4516 - modal = false until this is fixed
    <Dialog open={isModalOpen} modal={false}>
      <DialogTrigger onClick={openModal} asChild>
        <Button>{t('projectDetail.addUserForm.buttonLabels.addUser')}</Button>
      </DialogTrigger>
      <DialogContent onCloseClick={closeModal}>
        <DialogHeader>
          <DialogTitle>{t('projectDetail.addUserForm.title')}</DialogTitle>
          <DialogDescription>
            {t('projectDetail.addUserForm.subtitle')}
          </DialogDescription>
        </DialogHeader>
        <CreateUserForm closeModal={closeModal} />
      </DialogContent>
    </Dialog>
  );
};
