import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  Input,
} from 'me-component-library';
import {
  useGetProjectDetail,
  useTestCustomSMTPSender,
  useTranslation,
} from '../../hooks';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

export const EmailSenderFormTestModal: React.FC = () => {
  const t = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const {
    mutateAsync,
    isLoading: testIsLoading,
    isError,
    isSuccess,
  } = useTestCustomSMTPSender();
  const params = useParams();
  const { data, isLoading: dataIsLoading } = useGetProjectDetail(
    params.id as string,
  );

  const isMissingData =
    !data?.customSenderEmail ||
    !data?.customSenderServerPort ||
    !data?.customSenderServer ||
    !data?.customSenderUsername;

  const formSchema = z.object({
    email: z.string().email().min(1),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
    },
    reValidateMode: 'onBlur',
  });

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  async function onSubmit(values: z.infer<typeof formSchema>) {
    try {
      await mutateAsync({
        testTargetEmailAddress: values.email,
        projectId: params.id as string,
      });
    } catch (error) {
      console.error(error);
    }
  }

  if (dataIsLoading) {
    return <div>...</div>;
  }

  return (
    <Dialog open={isModalOpen}>
      <DialogTrigger onClick={openModal} disabled={isMissingData} asChild>
        <Button>
          {t(
            'projectDetail.emailSenderForm.emailSenderTestModal.openModalButton',
          )}
        </Button>
      </DialogTrigger>
      <DialogContent onCloseClick={closeModal}>
        <DialogHeader>
          <DialogTitle>
            {t('projectDetail.emailSenderForm.emailSenderTestModal.title')}
          </DialogTitle>
          <DialogDescription>
            {t('projectDetail.emailSenderForm.emailSenderTestModal.subtitle', {
              senderEmail: data?.customSenderEmail,
            })}
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form className="space-y-8">
            <Input
              className="hidden"
              name="username"
              hidden={true}
              autoComplete="username"
            />
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t(
                      'projectDetail.emailSenderForm.emailSenderTestModal.fields.email.label',
                    )}
                  </FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t(
                        'projectDetail.emailSenderForm.emailSenderTestModal.fields.email.placeholder',
                      )}
                      type="email"
                      {...field}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <Button
              isLoading={testIsLoading}
              onClick={form.handleSubmit(onSubmit)}
            >
              {t(
                'projectDetail.emailSenderForm.emailSenderTestModal.actionButton',
              )}
            </Button>
          </form>
          {isSuccess && (
            <div className="text-green-500 font-bold">
              {t(
                'projectDetail.emailSenderForm.emailSenderTestModal.testResult.success',
              )}
            </div>
          )}
          {isError && (
            <div className="text-red-500 font-bold">
              {t(
                'projectDetail.emailSenderForm.emailSenderTestModal.testResult.error',
              )}
            </div>
          )}
        </Form>
      </DialogContent>
    </Dialog>
  );
};
