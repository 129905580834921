import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { useGetProjectDetail, useTranslation } from '../../hooks';
import { useParams } from 'react-router-dom';
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  ToggleSwitch,
} from 'me-component-library';
import { useUpdateProjectDetails } from '../../hooks';
import { SkeletonList } from '../SkeletonList';

const convertBytesToGB = (bytes: number) => {
  return `${(bytes / (1000 * 1000 * 1000)).toFixed(2)}`;
};

const convertToBytes = (gb: string) => {
  return Math.round(parseFloat(gb.replace(',', '.')) * 1000 * 1000 * 1000);
};

export const EmailQuotaForm = () => {
  const t = useTranslation();
  const params = useParams();
  const { data, isLoading } = useGetProjectDetail(params.id as string);
  const { mutate } = useUpdateProjectDetails(params.id as string);

  const formSchema = z.object({
    totalMailboxQuotaEnabled: z.boolean(),
    totalMailboxQuota: z
      .string()
      .refine(value => /^\d+([.,]\d+)?$/.test(value), {
        message: t('projectDetail.emailQuotaForm.form.errors.invalidQuota'),
      }),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      totalMailboxQuotaEnabled: data?.totalMailboxQuotaEnabled,
      totalMailboxQuota: convertBytesToGB(data?.totalMailboxQuota ?? 0),
    },
  });

  async function onSubmit(values: z.infer<typeof formSchema>) {
    mutate(
      {
        projectId: params.id as string,
        totalMailboxQuota: convertToBytes(values.totalMailboxQuota),
        totalMailboxQuotaEnabled: values.totalMailboxQuotaEnabled,
      },
      {
        onSuccess: () => {
          form.reset({
            totalMailboxQuotaEnabled: values.totalMailboxQuotaEnabled,
            totalMailboxQuota: values.totalMailboxQuota,
          });
        },
      },
    );
  }

  const onReset = () => {
    form.reset();
  };

  if (isLoading || !data)
    return <SkeletonList count={2} itemClassName="w-full h-52 rounded-md" />;

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="w-full pb-3">
        <div className="flex w-auto items-end justify-end gap-4 ml-auto">
          <Button type="submit" disabled={!form.formState.isDirty}>
            {t('projectDetail.appearanceForm.buttonLabels.update')}
          </Button>
          <Button
            onClick={onReset}
            variant="outline"
            disabled={!form.formState.isDirty}
          >
            {t('projectDetail.appearanceForm.buttonLabels.reset')}
          </Button>
        </div>
        <div className="flex flex-col gap-12 border border-slate-200 rounded-lg mt-6 py-8 px-12">
          <div className="flex gap-16">
            <div className="flex flex-col w-full gap-4">
              <FormField
                control={form.control}
                name="totalMailboxQuotaEnabled"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-xl text-slate-700">
                      {t(
                        'projectDetail.emailQuotaForm.form.totalMailboxQuotaEnabled.label',
                      )}
                    </FormLabel>
                    <p className="text-sm text-slate-500 mt-0.5">
                      {t(
                        'projectDetail.emailQuotaForm.form.totalMailboxQuotaEnabled.help',
                      )}
                    </p>
                    <ToggleSwitch
                      activeLabel={t(
                        'projectDetail.emailQuotaForm.form.totalMailboxQuotaEnabled.toggleSwitch.active',
                      )}
                      inactiveLabel={t(
                        'projectDetail.emailQuotaForm.form.totalMailboxQuotaEnabled.toggleSwitch.inactive',
                      )}
                      enabled={field.value}
                      {...field}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="totalMailboxQuota"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-xl text-slate-700">
                      {t(
                        'projectDetail.emailQuotaForm.form.totalMailboxQuota.label',
                      )}
                    </FormLabel>
                    <p className="text-sm text-slate-500 mt-0.5">
                      {t(
                        'projectDetail.emailQuotaForm.form.totalMailboxQuota.help',
                      )}
                    </p>
                    <FormControl>
                      <div className="w-96 flex items-center relative">
                        <Input
                          placeholder="Gigabytes"
                          type="text"
                          {...field}
                          disabled={!form.getValues('totalMailboxQuotaEnabled')}
                        />
                        <span className="absolute right-0 mr-4 text-sm">
                          GB
                        </span>
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>
        </div>
      </form>
    </Form>
  );
};
