import { api } from './api';

export type DisableUserOtpParams = {
  projectId: string;
  userId: string;
};

export async function disableUserOtp({
  projectId,
  userId,
}: DisableUserOtpParams): Promise<boolean> {
  const response = await api.patch(
    `admin/projects/${projectId}/users/${userId}/2fa/disable`,
    {
      json: {},
    },
  );

  return response.ok;
}
