import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import * as z from 'zod';

import { useGetProjectDetail, useTranslation } from '../../hooks';
import { useParams } from 'react-router-dom';

import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  ToggleSwitch,
} from 'me-component-library';
import { useUpdateProjectDetails } from '../../hooks';
import { EmailSenderFormTestModal } from './EmailSenderFormTestModal';

export const EmailSenderForm = () => {
  const t = useTranslation();
  const params = useParams();
  const { data } = useGetProjectDetail(params.id as string);
  const { mutate } = useUpdateProjectDetails(params.id as string);

  const formSchema = z.object({
    enableCustomSenderEmail: z.boolean(),
    customSenderEmail: z.string().email().min(1),
    customSenderServerPort: z
      .union([z.string(), z.number()])
      .transform(val => (typeof val === 'string' ? parseInt(val, 10) : val)),
    customSenderServer: z.string().min(1),
    customSenderUsername: z.string().min(1),
    customSenderPassword: z.string().min(1),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      enableCustomSenderEmail: data?.enableCustomSenderEmail ?? false,
      customSenderEmail: data?.customSenderEmail ?? '',
      customSenderServer: data?.customSenderServer ?? '',
      customSenderServerPort: data?.customSenderServerPort ?? 0,
      customSenderUsername: data?.customSenderUsername ?? '',
      customSenderPassword: '',
    },
  });

  async function onSubmit(values: z.infer<typeof formSchema>) {
    mutate({
      projectId: params.id as string,
      ...values,
    });
  }

  const onReset = () => {
    form.reset();
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="w-full pb-3">
        <div className="flex w-auto items-end justify-end gap-4 ml-auto">
          <Button type="submit" disabled={!form.formState.isDirty}>
            {t('projectDetail.appearanceForm.buttonLabels.update')}
          </Button>
          <EmailSenderFormTestModal />
          <Button
            onClick={onReset}
            variant="outline"
            disabled={!form.formState.isDirty}
          >
            {t('projectDetail.appearanceForm.buttonLabels.reset')}
          </Button>
        </div>
        <div className="flex flex-col gap-12 border border-slate-200 rounded-lg mt-6 py-8 px-12">
          <div className="flex gap-16">
            <div className="flex flex-col w-full gap-4">
              <FormField
                control={form.control}
                name="enableCustomSenderEmail"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-xl text-slate-700">
                      {t(
                        'projectDetail.emailSenderForm.form.enableCustomSenderEmail.label',
                      )}
                    </FormLabel>
                    <p className="text-sm text-slate-500 mt-0.5">
                      {t(
                        'projectDetail.emailSenderForm.form.enableCustomSenderEmail.help',
                      )}
                    </p>
                    <ToggleSwitch
                      activeLabel={t(
                        'projectDetail.emailSenderForm.form.enableCustomSenderEmail.toggleSwitch.active',
                      )}
                      inactiveLabel={t(
                        'projectDetail.emailSenderForm.form.enableCustomSenderEmail.toggleSwitch.inactive',
                      )}
                      enabled={field.value}
                      {...field}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="customSenderEmail"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-xl text-slate-700">
                      {t(
                        'projectDetail.emailSenderForm.form.customSenderEmail.label',
                      )}
                    </FormLabel>
                    <p className="text-sm text-slate-500 mt-0.5">
                      {t(
                        'projectDetail.emailSenderForm.form.customSenderEmail.help',
                      )}
                    </p>
                    <FormControl>
                      <div className="w-96">
                        <Input
                          placeholder="farrokh.bulsara@example.com"
                          type="text"
                          {...field}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="customSenderServer"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-xl text-slate-700">
                      {t(
                        'projectDetail.emailSenderForm.form.customSenderServer.label',
                      )}
                    </FormLabel>
                    <p className="text-sm text-slate-500 mt-0.5">
                      {t(
                        'projectDetail.emailSenderForm.form.customSenderServer.help',
                      )}
                    </p>
                    <FormControl>
                      <div className="w-96">
                        <Input
                          placeholder="smtp.example.com"
                          type="text"
                          {...field}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="customSenderServerPort"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-xl text-slate-700">
                      {t(
                        'projectDetail.emailSenderForm.form.customSenderServerPort.label',
                      )}
                    </FormLabel>
                    <p className="text-sm text-slate-500 mt-0.5">
                      {t(
                        'projectDetail.emailSenderForm.form.customSenderServerPort.help',
                      )}
                    </p>
                    <FormControl>
                      <div className="w-96">
                        <Input placeholder="587" type="text" {...field} />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="customSenderUsername"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-xl text-slate-700">
                      {t(
                        'projectDetail.emailSenderForm.form.customSenderUsername.label',
                      )}
                    </FormLabel>
                    <p className="text-sm text-slate-500 mt-0.5">
                      {t(
                        'projectDetail.emailSenderForm.form.customSenderUsername.help',
                      )}
                    </p>
                    <FormControl>
                      <div className="w-96">
                        <Input
                          placeholder="farrokh.bulsara"
                          type="text"
                          {...field}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="customSenderPassword"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-xl text-slate-700">
                      {t(
                        'projectDetail.emailSenderForm.form.customSenderPassword.label',
                      )}
                    </FormLabel>
                    <p className="text-sm text-slate-500 mt-0.5">
                      {t(
                        'projectDetail.emailSenderForm.form.customSenderPassword.help',
                      )}
                    </p>
                    <FormControl>
                      <div className="w-96">
                        <Input
                          placeholder={t(
                            'projectDetail.emailSenderForm.form.customSenderPassword.placeholder',
                          )}
                          type="password"
                          {...field}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>
        </div>
      </form>
    </Form>
  );
};
